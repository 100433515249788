import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Operator } from '../model/operator.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { Program } from '../model/program.model';
import { Product } from '../model/product.model';

@Injectable()
export class OperatorsService {
  readonly operators$: BehaviorSubject<Operator[]>;
  readonly operator$: BehaviorSubject<Operator>;
  readonly DRGroups$: Subject<any[]>;
  private operatorsArr;

  constructor(private apiService: ApiService) {
    this.operators$ = new BehaviorSubject<Operator[]>([]);
    this.operator$ = new BehaviorSubject<Operator>(null);
    this.DRGroups$ = new Subject<any[]>();
    this.operatorsArr = [];
  }

  async fetchOperators() {
    try {
      const operators = await this.apiService.get(`operators`);
      if (this.operatorsArr && Array.isArray(this.operatorsArr)) {
        while (this.operatorsArr.length > 0) {
          this.operatorsArr.pop();
        }
      }
      this.operatorsArr.push(...operators.map(this.newOperator));
      this.operators$.next([...this.operatorsArr]);
    } catch (err) {
      console.log('Could not load operators list.', err);
    }
  }

  async fetchDrGroups() {
    const drGroups = await this.apiService.get('dr-groups');
    this.DRGroups$.next(convertKeys.toCamel(drGroups));
  }

  async fetchOperator(id: string) {
    if (id === null || id === '-1') {
      return;
    }
    const operator = await this.apiService.get(`operators/${id}`);
    this.operator$.next(this.newOperator(operator));
  }

  async refetchOperators() {
    await this.fetchOperators();
  }

  async createOperator(operator: Operator): Promise<Operator> {
    const dto = convertKeys.toSnake<any>(operator);
    dto.default_locale = operator.locale;
    dto.display_labels = operator.displayLabels;
    dto.descriptions = operator.descriptions;
    dto.short_display_labels = operator.shortDisplayLabels;
    const createdOperator = await this.apiService.post(`operators`, dto);
    return this.newOperator(createdOperator);
  }

  async deleteOperator(operatorId: string) {
    return await this.apiService.delete(`operators/${operatorId}`);
  }

  async updateOperator(operator: Operator) {
    const id = operator.id;
    const dto = convertKeys.toSnake<any>(operator);
    dto.default_locale = operator.locale;
    dto.display_label = operator.displayLabel;
    dto.display_labels = operator.displayLabels;
    dto.descriptions = operator.descriptions;
    dto.short_display_labels = operator.shortDisplayLabels;
    delete dto.children;
    const updatedOperator = await this.apiService.put(`operators/${id}`, dto);
    return this.newOperator(updatedOperator);
  }

  newOperator(rawOperator: any) {
    const operator = convertKeys.toCamel<any>(rawOperator);
    operator.displayLabels = rawOperator.display_labels;
    operator.descriptions = rawOperator.descriptions || {};
    operator.shortDisplayLabels = rawOperator.short_display_labels || {};

    operator.children = rawOperator.children;

    if (operator.alternateIds && operator.alternateIds.operatorExternalReferenceId) {
      operator.operatorExternalReferenceId = operator.alternateIds.operatorExternalReferenceId;
    }
    if (!Array.isArray(operator.children)) {
      operator.children = null;
      return Operator.create(operator);
    }
    operator.children = operator.children.map((rawProgram: any, programIndex: number ) => {
      const program = convertKeys.toCamel<any>(rawProgram);

      program.displayLabels = rawProgram.display_labels;
      program.descriptions = rawProgram.descriptions;
      program.children = rawProgram.children;

      if (!program.children) {
        program.children = null;
        return Program.create(program);
      }
      program.children = (program.children || []).map((rawProduct: any) => {
        const product = convertKeys.toCamel<any>(rawProduct);
        product.displayLabels = rawProduct.display_labels;
        product.descriptions = rawProduct.descriptions;

        return Product.create(product);
      });
      return Program.create(program);
    });

    return Operator.create(operator);
  }
}
