import { TreeNode } from 'ngx-tree-selector';
import { MarketEntity } from './market-entity.model';
import { Color } from '@angular-material-components/color-picker';

export class Product implements TreeNode, MarketEntity {
  static create(product: any) {
    return new Product(
      product.advanceNotice,
      product.baselineCapacity,
      product.baselineConfiguration,
      product.marketConfigurations,
      product.bonusMinutes,
      product.collectNotificationTime,
      product.controlTimeout,
      product.declaredAvailability,
      product.defaultDispatchDuration,
      product.defaultLocale,
      product.defaultNominationSchedule,
      product.description,
      product.descriptions,
      product.dispatchByPortfolioClearedOffers,
      product.dispatchMethod,
      product.dispatchSystem,
      product.displayLabel,
      product.displayLabels,
      product.shortDisplayLabels,
      product.drType,
      product.startDttm,
      product.endDttm,
      product.equipmentIntervalFrequency,
      product.expectedCapacitySource,
      product.gateRules,
      product.ecrmId,
      product.id,
      product.implementRegistrationLimits,
      product.allowFlexibleAssetOverlap,
      product.ignoreAvailability,
      product.includeNominationInComms,
      product.keyPerformanceMetric,
      product.marketConfiguration,
      product.maxEventDuration,
      product.maxEventsPerSeason,
      product.maxEventHoursPerSeason,
      product.minEventDuration,
      product.minDurationBetweenEvents,
      product.notificationConf,
      product.performanceAggregateType,
      product.performanceErrorThreshold,
      product.performanceWarningThreshold,
      product.performanceTargetMin,
      product.performanceTargetMax,
      product.portfolioObligationSource,
      product.prezConf,
      product.productExternalReferenceId,
      product.programId,
      product.reportingIntervalMs,
      product.scaleCapacityToObligation,
      product.schedule,
      product.seasonResetDate,
      product.selectionBufferMin,
      product.selectionBufferMax,
      product.selectionOfferTime,
      product.selectionType,
      product.selectionUpperTarget,
      product.status,
      product.targetType,
      product.timezone,
      product.underfrequencyProduct,
      product.weatherWindowStartOffset,
      product.weatherWindowEndOffset,
      product.dispatchConf,
      product.maximumConsecutiveDurations,
      product.maximumInvocationsPerDuration,
      product.maximumNotificationDuration,
      product.maximumResponseDuration,
      product.minimumNotificationDuration,
      product.minimumRecoveryDuration,
      product.minimumResponseDuration,
      product.incomplete,
      product.isDispatchableProduct,
      product.usePortfolioAvailability,
      product.ebGroupSyncSchedule,
    );
  }

  // tslint:disable-next-line:variable-name
  display_label: string;

  constructor(
    public advanceNotice = 0,
    public baselineCapacity = false,
    public baselineConfiguration: BaselineConfig,
    public marketConfigurations: MarketConfigurations,
    public bonusMinutes = 0,
    public collectNotificationTime = false,
    public controlTimeout,
    public declaredAvailability = false,
    public defaultDispatchDuration = 3600000,
    public defaultLocale = 'en_US',
    public defaultNominationSchedule = true,
    public description = '',
    public descriptions = {},
    public dispatchByPortfolioClearedOffers = false,
    public dispatchMethod,
    public dispatchSystem = 'FLEX',
    public displayLabel = '',
    public displayLabels = {},
    public shortDisplayLabels = {},
    public drType = '',
    public startDttm,
    public endDttm,
    public equipmentIntervalFrequency,
    public expectedCapacitySource = '',
    public gateRules: GateRules,
    public ecrmId = '',
    public id = '',
    public implementRegistrationLimits = false,
    public allowFlexibleAssetOverlap = true,
    public ignoreAvailability = false,
    public includeNominationInComms = false,
    public keyPerformanceMetric = '',
    public marketConfiguration,
    public maxEventDuration,
    public maxEventsPerSeason = 20,
    public maxEventHoursPerSeason,
    public minEventDuration = 300000,
    public minDurationBetweenEvents = 300000,
    public notificationConf: NotificationConfig,
    public performanceAggregateType = '',
    public performanceErrorThreshold = 100,
    public performanceWarningThreshold = 115,
    public performanceTargetMin = 100,
    public performanceTargetMax = 115,
    public portfolioObligationSource = '',
    public prezConf: PrezConfig,
    public productExternalReferenceId,
    public programId = '',
    public reportingIntervalMs,
    public scaleCapacityToObligation = false,
    public schedule: Schedule,
    public seasonResetDate,
    public selectionBufferMin = 50.0,
    public selectionBufferMax = 75.0,
    public selectionOfferTime = '',
    public selectionType = '',
    public selectionUpperTarget = 150.0,
    public status = statuses[0].id,
    public targetType = '',
    public timezone = 'America/New_York',
    public underfrequencyProduct = false,
    public weatherWindowStartOffset,
    public weatherWindowEndOffset,
    public dispatchConf: DispatchConf,
    public maximumConsecutiveDurations?,
    public maximumInvocationsPerDuration?,
    public maximumNotificationDuration?,
    public maximumResponseDuration?,
    public minimumNotificationDuration?,
    public minimumRecoveryDuration?,
    public minimumResponseDuration?,
    public incomplete?,
    public isDispatchableProduct?,
    public usePortfolioAvailability?,
    public ebGroupSyncSchedule?,
    public rampingPeriod = null,
    public postRampingPeriod = 120000,
  ) {
    this.display_label = this.displayLabel;

    if (!gateRules) {
      this.gateRules = {
        gateClosureDescription: '',
        gateClosureTolerance: null,
        fullDayOfferValue: false,
        postGateEdits: {
          allowPostGateClosureEdits: false,
          allowCurrentIntervalEdit: false,
          allowOfferDecrease: false,
          allowOfferIncrease: false,
          allowOfferEdit: true,
          allowIncreaseAfterDecrease: false,
          provideChangeReason: false,
        },
      };
    }

    if (gateRules && !gateRules.postGateEdits) {
      this.gateRules.postGateEdits = {
        allowPostGateClosureEdits: false,
        allowCurrentIntervalEdit: false,
        allowOfferDecrease: false,
        allowOfferIncrease: false,
        allowOfferEdit: true,
        allowIncreaseAfterDecrease: false,
        provideChangeReason: false,
      };
    }

    if (!schedule) {
      this.schedule = {
        offerFrequency: null,
        offerFrequencyUnit: '',
        offerGroupUnit: '',
        startDttm: '',
        endDttm: '',
        rule: '',
      };
    }

    if (!prezConf) {
      this.prezConf = {
        preferedPrezDemandUom: '',
        eventManagementHexCode: new Color(255, 0, 0, 1) as any,
        hideClearedTab: false,
        hideBaselinesTab: true,
      };
    }

    if (!dispatchConf) {
      this.dispatchConf = {
        postBonusMinutes: 0,
        includeBonusMinutes: false,
      };
    }
    if (!baselineConfiguration) {
      this.baselineConfiguration = {
        baselineAdjustmentAllowOverride: false,
        enableEventTriggeredBaseline: false,
      };
    }

    if (!marketConfigurations) {
      this.marketConfigurations = {
        usePortfolioAvailability: null,
      };
    }

    if (!notificationConf) {
      this.notificationConf = {
        notifyBeforeGate: false,
        notifyInGate: false,
        template: null,
      };
    }
  }
}

export interface GateRules {
  gateClosureDescription: string;
  gateClosureTolerance: number;
  gateClosesOn?: string;
  gateClosesFromSpecific?: string;
  gateClosesToSpecific?: string;
  gateClosesEvery?: string;
  gateClosesFromRelative?: number;
  gateClosesToRelative?: number;
  postGateEdits?: PostGateEdits;
  fullDayOfferValue: boolean;
}

export interface PostGateEdits {
  allowPostGateClosureEdits: boolean;
  allowOfferIncrease: boolean;
  allowOfferDecrease: boolean;
  allowOfferEdit: boolean;
  allowCurrentIntervalEdit: boolean;
  allowIncreaseAfterDecrease: boolean;
  provideChangeReason: boolean;
}

export interface PrezConfig {
  preferedPrezDemandUom: string;
  eventManagementHexCode: string;
  rampDown?: number;
  rampUp?: number;
  hideClearedTab: boolean;
  hideBaselinesTab: boolean;
}

export interface BaselineConfig {
  baselineAdjustmentAllowOverride?: boolean;
  enableEventTriggeredBaseline?: boolean;
  baselineAdjustmentDuration?: number;
  baselineAdjustmentOffset?: number;
  baselineAdjustmentOffsetTime?: string;
  baselineLookbackWindowSize?: number;
  baselineLookbackType?: string;
}

export interface NotificationConfig {
  notifyBeforeGate?: boolean;
  notifyInGate?: boolean;
  template?: string;
}

export interface Schedule {
  offerFrequency: number;
  offerFrequencyUnit: string;
  offerGroupUnit: string;
  startDttm: string;
  endDttm: string;
  rule: string;
}

export interface MarketConfigurations {
  usePortfolioAvailability: boolean;
}

export interface DispatchConf {
  rampingPeriod?: number;
  postRampingPeriod?: number;
  ignoreAvailabilityForSelection?: boolean;
  siteListEventNotifications?: boolean;
  siteListNonEventNotifications?: boolean;
  dispatchSystem?: string;
  // availability_channel?: number;
  // performance_channel?: number;
  postBonusMinutes: number;
  includeBonusMinutes: boolean;
  dispatchInstructions?: string;
  managementEmail?: string;
  maxEventsPerDay?: number;
  maxEventsPerWeek?: number;
  maxEventsPerMonth?: number;
}

export const statuses = [
  {
    id: 'ACTIVE',
    displayLabel: 'common.active',
  },
  {
    id: 'INACTIVE',
    displayLabel: 'common.inactive',
  },
];

export interface RefData {
  code: string;
  displayLabel: string;
  id: string;
  durationInMilliseconds?: string;
  granularityName?: string;
}
