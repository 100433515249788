import { TreeNode } from 'ngx-tree-selector';
import { MarketEntity } from './market-entity.model';
import { Product } from './product.model';
export class Program implements TreeNode, MarketEntity {
  static create(program: any) {
    return new Program(
      program.id,
      program.accountNumberDisplayLabel,
      program.children,
      program.classicProgramName,
      program.currencyCode,
      program.locale || program.defaultLocale,
      program.description,
      program.descriptions,
      program.discontinueDttm,
      program.displayLabel || program.name,
      program.displayLabels,
      program.drType,
      program.endDttm,
      program.enrollmentOffset,
      program.holidayCalendarName,
      program.mandatoryHours,
      program.minimumEconomicRequirement,
      program.minimumStartsPerDuration,
      program.operatorId,
      program.operatorDisplayLabel,
      program.participantIdentifier,
      program.programExternalReferenceId,
      program.programName,
      program.requiresMarketOffers,
      program.shortDisplayLabel,
      program.shortDisplayLabels,
      program.startDttm,
      program.status,
      program.timezone,
      program.voluntaryHours,
      program.incomplete,
      program.isDispatchableProgram,
      program.notificationConf,
      program.defaultNominationSchedule,
      program.schedule,
      program.gateRules,
    );
  }

  // tslint:disable-next-line:variable-name
  display_label: string;

  constructor(
    public id = '',
    public accountNumberDisplayLabel = '',
    public children: Product[] = [],
    public classicProgramName = '',
    public currencyCode = '',
    public locale = 'en_US',
    public description = '',
    public descriptions = {},
    public discontinueDttm: Date,
    public displayLabel = '',
    public displayLabels = {},
    public drType = '',
    public endDttm: Date,
    public enrollmentOffset = '',
    public holidayCalendarName = '',
    public mandatoryHours = 0,
    public minimumEconomicRequirement = 0,
    public minimumStartsPerDuration = 0,
    public operatorId = '',
    public operatorDisplayLabel = '',
    public participantIdentifier = '',
    public programExternalReferenceId = '',
    public programName = '',
    public requiresMarketOffers = true,
    public shortDisplayLabel = '',
    public shortDisplayLabels = {},
    public startDttm: Date,
    public status = statuses[0].id,
    public timezone = 'America/New_York',
    public voluntaryHours = 0,
    public incomplete = false,
    public isDispatchableProgram,
    public notificationConf: NotificationConfig,
    public defaultNominationSchedule = true,
    public schedule: ProgramSchedule,
    public gateRules: ProgramGateRules,
  ) {
    this.display_label = this.displayLabel;

    if (!gateRules) {
      this.gateRules = {
        gateClosureDescription: '',
        gateClosureTolerance: null,
        fullDayOfferValue: false,
        postGateEdits: {
          allowPostGateClosureEdits: false,
          allowCurrentIntervalEdit: false,
          allowOfferDecrease: false,
          allowOfferIncrease: false,
          allowIncreaseAfterDecrease: false,
          provideChangeReason: false,
        },
      };
    }

    if (gateRules && !gateRules.postGateEdits) {
      this.gateRules.postGateEdits = {
        allowPostGateClosureEdits: false,
        allowCurrentIntervalEdit: false,
        allowOfferDecrease: false,
        allowOfferIncrease: false,
        allowIncreaseAfterDecrease: false,
      };
    }

    if (!schedule) {
      this.schedule = {
        offerFrequency: null,
        offerFrequencyUnit: '',
        offerGroupUnit: '',
        startDttm: '',
        endDttm: '',
        rule: '',
      };
    }

    if (!notificationConf) {
      this.notificationConf = {
        notifyBeforeGate: false,
        notifyInGate: false,
        template: null,
      };
    }
  }
}

export const statuses = [
  {
    id: 'ACTIVE',
    display_label: 'common.active',
  },
  {
    id: 'INACTIVE',
    display_label: 'common.inactive',
  },
];

export interface ProgramSchedule {
  offerFrequency: number;
  offerFrequencyUnit: string;
  offerGroupUnit: string;
  startDttm: string;
  endDttm: string;
  rule: string;
}

export interface ProgramGateRules {
  gateClosureDescription: string;
  gateClosureTolerance: number;
  gateClosesOn?: string;
  gateClosesFromSpecific?: string;
  gateClosesToSpecific?: string;
  gateClosesEvery?: string;
  gateClosesFromRelative?: number;
  gateClosesToRelative?: number;
  postGateEdits?: PostGateEdits;
  fullDayOfferValue: boolean;
}

export interface PostGateEdits {
  allowPostGateClosureEdits: boolean;
  allowOfferIncrease: boolean;
  allowOfferDecrease: boolean;
  allowCurrentIntervalEdit: boolean;
  allowIncreaseAfterDecrease: boolean;
  provideChangeReason?: boolean;
}

export interface NotificationConfig {
  notifyBeforeGate?: boolean;
  notifyInGate?: boolean;
  template?: string;
}

export interface RefData {
  code: string;
  displayLabel: string;
  id: string;
  durationInMilliseconds?: string;
}
