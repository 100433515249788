import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { Product } from '../model/product.model';
import { ApiService } from './api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Color } from '@angular-material-components/color-picker';
import { ProductStub } from '../model/product-stub.model';

@Injectable()
export class ProductsService {
  product$: BehaviorSubject<Product | ProductStub>;
  partialSuccessWarning$ = new Subject<any>();
  conflictError$ = new Subject<any>();

  constructor(private apiService: ApiService) {
    this.product$ = new BehaviorSubject<Product>(null);
  }

  async fetchProduct(id: string) {
    if (id === null || id === '-1') {
      return;
    }
    let productResp = await this.apiService.get(`products/${id}`);

    if (productResp.isConflict) {
      const { data } = productResp;
      productResp = data.product;
      this.conflictError$.next(productResp);
    }

    const product = convertKeys.toCamel<any>(productResp);

    product.displayLabels = productResp.display_labels;
    product.descriptions = productResp.descriptions || {};
    product.shortDisplayLabels = productResp.short_display_labels || {};

    product.productExternalReferenceId = product.alternateIds ? product.alternateIds.productExternalReferenceId : '';
    product.ecrmId = product.alternateIds ? product.alternateIds.ecrmId : '';
    delete product.alternateIds;

    if (product.prezConf && product.prezConf.eventManagementHexCode) {
      const { r, g, b } = this.hexToRGB(product.prezConf.eventManagementHexCode);
      product.prezConf.eventManagementHexCode = new Color(r, g, b, 1);
    }

    const { schedule, gateRules, prezConf } = product;
    product.isDispatchableProduct = schedule !== undefined && gateRules !== undefined && prezConf !== undefined;

    const newProduct = Product.create({ ...product });
    this.product$.next(newProduct);
  }

  async createProduct(product: Product | ProductStub): Promise<Product | ProductStub> {
    const { isDispatchableProduct } = product;

    const dto = convertKeys.toSnake<any>(product);
    // avoid decode failure in COM for ramp_period = 0
    if (dto.dispatch_conf.ramping_period === 0) {
      delete dto.dispatch_conf.ramping_period;
    }

    dto.display_labels = product.displayLabels;
    dto.descriptions = product.descriptions;
    dto.short_display_labels = product.shortDisplayLabels;

    // temp removed fields
    dto.equipment_interval_frequency = 10;
    dto.prez_conf.prefered_prez_demand_uom = 'kW';
    dto.schedule.start_dttm = dto.start_dttm;
    dto.schedule.end_dttm = dto.end_dttm;
    dto.weather_window_end_offset = 10;
    dto.weather_window_start_offset = 10;
    // dto.notification_conf.notify_before_gate = false;
    // dto.notification_conf.template = '';

    let createdProduct: Product;

    if (isDispatchableProduct) {
      const { hex } = dto.prez_conf.event_management_hex_code;
      dto.prez_conf.event_management_hex_code = `#${hex}`;
      createdProduct = await this.apiService.post(`products`, dto);
    } else {
      createdProduct = await this.apiService.post(`product-stubs`, dto);
    }
    return this.newProduct(createdProduct);
  }

  async deleteProduct(productId: string, isDispatachableProduct) {
    if (isDispatachableProduct) {
      return await this.apiService.delete(`products/${productId}`);
    } else {
      return await this.apiService.delete(`product-stubs/${productId}`);
    }
  }

  async updateProduct(product: Product) {
    const id = product.id;

    // avoid decode failure in COM for ramp_period = 0
    if (product.dispatchConf.rampingPeriod === 0) {
      delete product.dispatchConf.rampingPeriod;
    }

    const dto = convertKeys.toSnake<any>(product);
    const { isDispatchableProduct } = product;
    let updatedProduct: Product;

    this.removeEmpty(dto);
    dto.display_labels = product.displayLabels;
    dto.descriptions = product.descriptions;
    dto.short_display_labels = product.shortDisplayLabels;

    // temp removed fields
    dto.equipment_interval_frequency = 10;
    dto.prez_conf.prefered_prez_demand_uom = 'kW';
    dto.schedule.start_dttm = dto.start_dttm;
    dto.schedule.end_dttm = dto.end_dttm;
    dto.weather_window_end_offset = 10;
    dto.weather_window_start_offset = 10;
    // dto.notification_conf.notify_before_gate = false;
    // dto.notification_conf.template = '';

    if (isDispatchableProduct) {
      const { hex } = dto.prez_conf.event_management_hex_code;
      dto.prez_conf.event_management_hex_code = `#${hex}`;
      updatedProduct = await this.apiService.put(`products/${id}`, dto);
    } else {
      updatedProduct = await this.apiService.put(`product-stubs/${id}`, dto);
    }

    return this.newProduct(updatedProduct);
  }

  newProduct(rawProduct: any) {
    const product = convertKeys.toCamel<any>(rawProduct);
    product.displayLabels = rawProduct.display_labels;
    product.descriptions = rawProduct.descriptions || {};
    product.shortDisplayLabels = rawProduct.short_display_labels || {};

    const { schedule, gateRules, prezConf } = product;

    product.isDispatchableProduct = schedule !== undefined && gateRules !== undefined && prezConf !== undefined;
    product.ecrmId = product.alternateIds ? product.alternateIds.ecrmId : '';

    if (product.isDispatchableProduct) {
      return Product.create(product);
    } else {
      return ProductStub.create(product);
    }
  }

  removeEmpty = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key]);
      } else if (obj[key] === null || obj[key] === '') {
        delete obj[key];
      }
    });
  };

  hexToRGB(hex: string) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
  }
}
