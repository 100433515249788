import { Program } from './program.model';
import { TreeNode } from 'ngx-tree-selector';
import { MarketEntity } from './market-entity.model';

export class Operator implements TreeNode, MarketEntity {
  static create(operator: any) {
    return new Operator(
      operator.id,
      operator.locale || operator.defaultLocale,
      operator.description,
      operator.descriptions,
      operator.children,
      operator.discontinueDttm,
      operator.displayLabel,
      operator.displayLabels,
      operator.shortDisplayLabels,
      operator.drType,
      operator.programId,
      operator.registrations,
      operator.shortDisplayLabel,
      operator.status,
      operator.timezone,
      operator.drGroupId,
      operator.operatorExternalReferenceId,
    );
  }

  // tslint:disable-next-line:variable-name
  display_label: string;

  constructor(
    public id = '',
    public locale = 'en_US',
    public description = '',
    public descriptions = {},
    public children: Program[],
    public discontinueDttm = '',
    public displayLabel = '',
    public displayLabels = {},
    public shortDisplayLabels = {},
    public drType = '',
    public programId = '',
    public registrations: string[],
    public shortDisplayLabel = '',
    public status = statuses[0].id,
    public timezone = 'America/New_York',
    public drGroupId = '',
    public operatorExternalReferenceId = '',
  ) {
    // Removing [${this.children.length}] from the line below.
    this['display_label'] = this.displayLabel;
  }
}

export const statuses = [
  {
    id: 'ACTIVE',
    display_label: 'common.active',
  },
  {
    id: 'INACTIVE',
    display_label: 'common.inactive',
  },
];
