<div data-automation="productPerformanceFieldsPanel" ngModelGroup="dispatchableFields">
    <ng-container>
        <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
            (afterExpand)="onPanelExpand()"
            (afterCollapse)="onPanelExpand()"
            togglePosition="before"
            class="expansion-panel"
            id="product-performance-panel"
            data-automation="productPerformancePanel"
        >
            <mat-expansion-panel-header collapsedHeight="48px" expandedHeight="48px">
                <mat-panel-title class="panel-title">
                    <div data-automation="productPerformancePanelTitle">
                        {{ 'common.product.performance' | translate }}
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <!--- CREATE/EDIT --->
            <div *ngIf="(isCreateMode || isEditMode) && product" class="panel-content">
                <div class="form-group">
                    <ng-container
                        ngModelGroup="minPerformanceErrorThreshold"
                        #minPerformanceErrorThreshold="ngModelGroup"
                        minMaxEqualValidator
                        [minEqualControl]="performanceErrorThreshold"
                        [maxEqualControl]="performanceWarningThreshold"
                    >
                        <!--- performance error threshold --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.performance_error_threshold' | translate }}</mat-label>
                                <input
                                    matInput
                                    id="performanceErrorThreshold"
                                    type="number"
                                    step="1"
                                    #performanceErrorThreshold="ngModel"
                                    name="performanceErrorThreshold"
                                    placeholder="100"
                                    maxlength="16"
                                    [(ngModel)]="product.performanceErrorThreshold"
                                    data-automation="performanceErrorThreshold"
                                    data-url-persist
                                    required
                                />

                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="performanceErrorThresholdTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.performance_error_threshold.tooltip' | translate }}"
                                ></mat-icon>

                                <mat-error *ngIf="performanceErrorThreshold.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="performanceErrorThreshold.errors.required">
                                        <span class="input-title"></span>
                                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!--- performance warning threshold --->
                        <div class="form-entry">
                            <mat-form-field appearance="outline" floatLabel="always">
                                <mat-label>{{ 'common.performance_warning_threshold' | translate }}</mat-label>
                                <input
                                    matInput
                                    id="performanceWarningThreshold"
                                    type="number"
                                    step="1"
                                    #performanceWarningThreshold="ngModel"
                                    name="performanceWarningThreshold"
                                    placeholder="115"
                                    maxlength="16"
                                    [(ngModel)]="product.performanceWarningThreshold"
                                    data-automation="performanceWarningThreshold"
                                    data-url-persist
                                    required
                                />

                                <mat-icon
                                    matSuffix
                                    svgIcon="enelx:general-info"
                                    data-automation="performanceWarningThresholdTooltip"
                                    class="tooltip-icon"
                                    [matTooltipPosition]="'below'"
                                    matTooltip="{{ 'common.performance_warning_threshold.tooltip' | translate }}"
                                ></mat-icon>

                                <mat-error *ngIf="performanceWarningThreshold.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="performanceWarningThreshold.errors.required">
                                        <span class="input-title"></span>
                                        <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-error class="group-error" *ngIf="minPerformanceErrorThreshold.invalid">
                            <mat-error
                                *ngIf="
                                    minPerformanceErrorThreshold.errors &&
                                    minPerformanceErrorThreshold.errors.minMaxEqualValid
                                "
                            >
                                <span class="input-title"></span>
                                <span class="input-entry" data-automation="validationMessage">{{
                                    'common.errors.performance_error_threshold' | translate
                                }}</span>
                            </mat-error>
                        </mat-error>
                    </ng-container>

                    <!--- performance aggregate type --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.performance_aggregate_type' | translate }}</mat-label>
                            <mat-select
                                id="performanceAggregateType"
                                name="performanceAggregateType"
                                #performanceAggregateType="ngModel"
                                [(ngModel)]="product.performanceAggregateType"
                                data-automation="performanceAggregateTypeSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.performance_aggregate_type' | translate }}"
                            >
                                <mat-option>{{
                                    'product.create.placeholder.performance_aggregate_type' | translate
                                }}</mat-option>
                                <mat-option
                                    *ngFor="let performanceAggregateType of performanceAggregateTypes"
                                    [value]="performanceAggregateType.code"
                                    id="{{ performanceAggregateType.code }}"
                                >
                                    {{ performanceAggregateType.displayLabel }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="performanceAggregateTypeTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.performance_aggregate_type.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="performanceAggregateType.invalid" class="alert alert-danger">
                                <mat-error *ngIf="performanceAggregateType.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- key performance metric --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.key_performance_metric' | translate }}</mat-label>
                            <mat-select
                                id="keyPerformanceMetric"
                                name="keyPerformanceMetric"
                                #keyPerformanceMetric="ngModel"
                                [(ngModel)]="product.keyPerformanceMetric"
                                data-automation="keyPerformanceMetricSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.key_performance_metric' | translate }}"
                            >
                                <mat-option>{{
                                    'product.create.placeholder.key_performance_metric' | translate
                                }}</mat-option>
                                <mat-option
                                    *ngFor="let keyPerformanceMetric of keyPerformanceMetrics"
                                    [value]="keyPerformanceMetric.code"
                                    id="{{ keyPerformanceMetric.code }}"
                                >
                                    {{ keyPerformanceMetric.displayLabel }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="keyPerformanceMetricTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.key_performance_metric.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="keyPerformanceMetric.invalid" class="alert alert-danger">
                                <mat-error *ngIf="keyPerformanceMetric.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- ramping period --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.dispatch_conf.ramping_period' | translate }}</mat-label>
                            <input
                                matInput
                                id="rampingPeriod"
                                type="number"
                                step="1"
                                #maxEventsPerMonth="ngModel"
                                name="rampingPeriod"
                                placeholder="0"
                                maxlength="16"
                                [min]="0"
                                [(ngModel)]="product.dispatchConf.rampingPeriod"
                                data-automation="rampingPeriod"
                                data-url-persist
                            />

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="rampingPeriodTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.ramping_period.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-form-field>
                    </div>

                    <!--- post ramping period --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.dispatch_conf.post_ramping_period' | translate }}</mat-label>
                            <input
                                matInput
                                id="postRampingPeriod"
                                type="number"
                                step="1"
                                #maxEventsPerMonth="ngModel"
                                name="postRampingPeriod"
                                placeholder="1200000"
                                maxlength="16"
                                [min]="0"
                                [(ngModel)]="product.dispatchConf.postRampingPeriod"
                                data-automation="postRampingPeriod"
                                data-url-persist
                            />

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="postRampingPeriodTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.post_ramping_period.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-form-field>
                    </div>

                    <!--- reporting interval --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.reporting_interval' | translate }}</mat-label>
                            <mat-select
                                id="reportingIntervalMs"
                                name="reportingIntervalMs"
                                #reportingInterval="ngModel"
                                [(ngModel)]="product.reportingIntervalMs"
                                data-automation="reportingIntervalSelector"
                                data-url-persist
                                required
                                placeholder="{{ 'product.create.placeholder.reporting_interval' | translate }}"
                                [compareWith]="compareReportingInterval"
                            >
                                <mat-option>{{
                                    'product.create.placeholder.reporting_interval' | translate
                                }}</mat-option>
                                <mat-option
                                    *ngFor="let reportingInterval of reportingIntervals"
                                    [value]="reportingInterval.durationInMilliseconds"
                                    id="{{ reportingInterval.granularityName }}"
                                >
                                    {{ reportingInterval.displayLabel }}
                                </mat-option>
                            </mat-select>

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="reportingIntervalTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.reporting_interval.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="reportingInterval.invalid" class="alert alert-danger">
                                <mat-error *ngIf="reportingInterval.errors.required">
                                    {{ 'common.validation.required' | translate }}
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- event management hex code --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.prez_config.event_management_hex_code' | translate }}</mat-label>
                            <input
                                matInput
                                [ngxMatColorPicker]="picker"
                                #eventManagementHexCode="ngModel"
                                id="eventManagementHexCode"
                                name="eventManagementHexCode"
                                [(ngModel)]="product.prezConf.eventManagementHexCode"
                            />
                            <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
                            <ngx-mat-color-picker #picker [touchUi]="false"> </ngx-mat-color-picker>
                        </mat-form-field>
                    </div>

                    <!--- bonus minutes --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.bonus_minutes' | translate }}</mat-label>
                            <input
                                matInput
                                id="bonusMinutes"
                                type="number"
                                step="1"
                                #bonusMinutes="ngModel"
                                name="bonusMinutes"
                                placeholder="0"
                                maxlength="16"
                                [(ngModel)]="product.bonusMinutes"
                                data-automation="bonusMinutes"
                                data-url-persist
                                required
                            />

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="bonusMinutesTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.bonus_minutes.tooltip' | translate }}"
                            ></mat-icon>

                            <mat-error *ngIf="bonusMinutes.invalid" class="alert alert-danger">
                                <mat-error *ngIf="bonusMinutes.errors.required">
                                    <span class="input-title"></span>
                                    <span class="input-entry">{{ 'common.validation.required' | translate }}</span>
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <!--- post bonus minutes --->
                    <div class="form-entry">
                        <mat-form-field appearance="outline" floatLabel="always">
                            <mat-label>{{ 'common.dispatch_conf.post_bonus_minutes' | translate }}</mat-label>
                            <input
                                matInput
                                id="postBonusMinutes"
                                type="number"
                                step="1"
                                #postBonusMinutes="ngModel"
                                name="postBonusMinutes"
                                placeholder="0"
                                maxlength="16"
                                [min]="0"
                                [(ngModel)]="product.dispatchConf.postBonusMinutes"
                                data-automation="postBonusMinutes"
                                data-url-persist
                            />

                            <mat-icon
                                matSuffix
                                svgIcon="enelx:general-info"
                                data-automation="postBonusMinutesTooltip"
                                class="tooltip-icon"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.post_bonus_minutes.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-form-field>
                    </div>

                    <!--- include bonus minutes --->
                    <div class="form-entry">
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="includeBonusMinutes"
                                #includeBonusMinutes="ngModel"
                                class="input-entry checkbox-container"
                                name="includeBonusMinutes"
                                [(ngModel)]="product.dispatchConf.includeBonusMinutes"
                                data-automation="includeBonusMinutes"
                                data-url-persist
                            >
                                {{ 'common.dispatch_conf.include_bonus_minutes' | translate }}
                            </mat-checkbox>

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="includeBonusMinutesTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.include_bonus_minutes.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </div>

                    <!--- declared Availability --->
                    <div class="form-entry">
                        <div class="checkbox-wrapper">
                            <mat-checkbox
                                id="declaredAvailability"
                                #declaredAvailability="ngModel"
                                class="input-entry checkbox-container"
                                name="declaredAvailability"
                                [(ngModel)]="product.declaredAvailability"
                                data-automation="declaredAvailability"
                                data-url-persist
                            >
                                {{ 'common.declared_availability' | translate }}
                            </mat-checkbox>

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="declaredAvailabilityTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.declared_availability.tooltip' | translate }}"
                            ></mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <!--- VIEW --->
            <div *ngIf="isViewMode && product" class="panel-content">
                <div class="form-group">
                    <!--- performance error threshold --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.performance_error_threshold' | translate }}
                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="performanceErrorThresholdTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.performance_error_threshold.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="performanceErrorThreshold">
                            {{ product.performanceErrorThreshold }}
                        </mat-label>
                    </div>

                    <!--- performance warning threshold --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.performance_warning_threshold' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="performanceWarningThresholdTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.performance_warning_threshold.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="performanceWarningThreshold">
                            {{ product.performanceWarningThreshold }}
                        </mat-label>
                    </div>

                    <!--- performance aggregate type --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.performance_aggregate_type' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="performanceAggregateTypeTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.performance_aggregate_type.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="performanceAggregateType">
                            {{ performanceAggregateTypeForProduct }}
                        </mat-label>
                    </div>

                    <!--- key performance metric --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.key_performance_metric' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="keyPerformanceMetricTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.key_performance_metric.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="keyPerformanceMetric">
                            {{ keyPerformanceMetricForProduct }}
                        </mat-label>
                    </div>

                    <!--- ramping period --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.dispatch_conf.ramping_period' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="rampingPeriodTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.ramping_period.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="rampingPeriod">
                            {{ product.dispatchConf.rampingPeriod }}
                        </mat-label>
                    </div>

                    <!--- post ramping period --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.dispatch_conf.post_ramping_period' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="postRampingPeriodTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.post_ramping_period.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="postRampingPeriod">
                            {{ product.dispatchConf.postRampingPeriod }}
                        </mat-label>
                    </div>

                    <!--- reporting interval --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.reporting_interval' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="reportingIntervalTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.reporting_interval.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="reportingInterval">
                            {{ reportingIntervalForProduct }}
                        </mat-label>
                    </div>

                    <!--- event management hex code --->
                    <div class="form-entry">
                        <mat-label class="info-title">{{
                            'common.prez_config.event_management_hex_code' | translate
                        }}</mat-label>
                        <mat-label class="input-entry" data-automation="eventManagementHexCode">
                            {{ product.prezConf.eventManagementHexCode }}
                            <span
                                class="hex-box"
                                [style.background-color]="product.prezConf.eventManagementHexCode"
                            ></span>
                        </mat-label>
                    </div>

                    <!--- bonus minutes --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.bonus_minutes' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="bonusMinutesTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.bonus_minutes.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="bonusMinutes">
                            {{ product.bonusMinutes }}
                        </mat-label>
                    </div>

                    <!--- post bonus minutes --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.dispatch_conf.post_bonus_minutes' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="postBonusMinutesTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.post_bonus_minutes.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="postBonusMinutes">
                            {{ product.dispatchConf.postBonusMinutes }}
                        </mat-label>
                    </div>

                    <!--- include bonus minutes --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.dispatch_conf.include_bonus_minutes' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="includeBonusMinutesTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.dispatch_conf.include_bonus_minutes.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="includeBonusMinutes">
                            {{ product.dispatchConf.includeBonusMinutes }}
                        </mat-label>
                    </div>

                    <!--- declared availability --->
                    <div class="form-entry">
                        <mat-label class="info-title"
                            >{{ 'common.declared_availability' | translate }}

                            <mat-icon
                                svgIcon="enelx:general-info"
                                data-automation="declaredAvailabilityTooltip"
                                class="tooltip-icon icon-container"
                                [matTooltipPosition]="'below'"
                                matTooltip="{{ 'common.declared_availability.tooltip' | translate }}"
                            ></mat-icon>
                        </mat-label>
                        <mat-label class="input-entry" data-automation="declaredAvailability">
                            {{ product.declaredAvailability }}
                        </mat-label>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </ng-container>
</div>
