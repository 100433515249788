<div class="spinner-container" *ngIf="loading">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
<ng-container *ngIf="!loading && operators">
    <tree-selector
        *ngIf="operators.length > 0"
        [treeData]="operators"
        [multiSelect]="multiSelect"
        [cascadeSelect]="cascadeSelect"
        [expandAll]="expandAll"
        (selection)="initSelection($event)"
        [iconIdentifier]="iconIdentifier"
        [iconDefinitions]="iconDefinitions"
        [defaultSelectedNodes]="selectedTreeNodes"
    >
    </tree-selector>
    <br />
    <div *ngIf="operators.length <= 0" class="alert-padding alert alert-warning">
        {{ 'list.none_configured' | translate }}
    </div>
</ng-container>

<div class="list-button-container">
    <button
        *ngIf="showCloneButton"
        (click)="routeToCreatePageClone()"
        [disabled]="disableCloneButton"
        id="clone-button"
        class="icon-btn x-btn-primary"
        color="accent"
        data-automation="cloneButton"
    >
        <mat-icon>content_copy</mat-icon>
        <span class="btn-text" data-automation="cloneButtonLabel">{{ 'common.clone' | translate }}</span>
    </button>
    <button (click)="routeToCreatePage()" id="create-button" data-automation="create-button" class="icon-btn x-btn-primary">
        <mat-icon>add</mat-icon>
        <span class="btn-text">{{ 'list.button.create' | translate }}</span>
    </button>
</div>
