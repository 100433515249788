import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TreeService {

  selectedTreeNode$ = new BehaviorSubject<string>('');
  setSelectedTreeNode(id) {
    this.selectedTreeNode$.next(id);
  }
}
