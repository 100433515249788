import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TreeNode, TreeSelectorComponent } from 'ngx-tree-selector';
import { SelectionModel } from '@angular/cdk/collections';
import { Operator } from '../shared/model/operator.model';
import { OperatorsService } from '../shared/services/operators.service';
import { SidenavService } from '../shared/services/sidenav.service';
import { TreeService } from '../shared/services/tree.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnDestroy {
  multiSelect = false;
  expandAll = false;
  cascadeSelect = false;
  operators: Operator[];
  dataSources = { operators: [] };
  selection: SelectionModel<TreeNode> | undefined;
  selectedTreeNodes: string[] = [];
  loading = true;
  iconIdentifier = 'drType';
  iconDefinitions = {
    Operator: {
      name: 'admin',
      type: 'svg',
      namespace: 'enelx',
    },
    Program: {
      name: 'path',
      type: 'svg',
      namespace: 'enelx',
    },
    Product: {
      name: 'plans',
      type: 'svg',
      namespace: 'enelx',
    },
  };

  private treeSelector: TreeSelectorComponent;
  showCloneButton = false;
  selectedRowId: any;
  selectedRowType: any;
  messages: any[] = [];
  subscription: Subscription;
  operatorSubscription: Subscription;
  disableCloneButton: boolean;
  @ViewChild(TreeSelectorComponent)
  set treeSelectorComponent(treeSelectorComponent: TreeSelectorComponent) {
    this.treeSelector = treeSelectorComponent;
  }

  constructor(
    private router: Router,
    private operatorsService: OperatorsService,
    private sidenavService: SidenavService,
    private treeService: TreeService,
  ) {
    this.operatorSubscription = this.operatorsService.operators$.subscribe((operators) => {
      if (operators.length > 0) {
        this.operators = [];
        this.operators = operators;
        this.loading = false;
      }
    });
    this.operatorsService.fetchOperators();
    this.treeService.selectedTreeNode$.subscribe((id) => {
      this.selectedTreeNodes = [id];
    });
    this.subscription = this.sidenavService.getMessage().subscribe((message) => {
      if (message) {
        this.disableCloneButton = message.flag;
      }
    });
  }

  initSelection(selection: SelectionModel<TreeNode>) {
    this.selection = selection;

    this.selection.changed.subscribe(() => {
      this.showCloneButton = this.selection?.selected?.length > 0;
      this.selectedRowType = this.selection.selected[0].drType;
      if (this.showCloneButton && this.selectedTreeNodes[0] !== this.selection.selected[0].id) {
        this.onRowClicked(this.selection.selected[0].id, this.selectedRowType);
      }
    });
  }

  onRowClicked(id, drType) {
    this.selectedRowId = id;
    this.treeService.setSelectedTreeNode(id);
    this.router.navigate([`details/${id}/view`], { queryParams: { drType } });
  }

  ngOnDestroy(): void {
    this.operatorSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  routeToCreatePageClone() {
    const drType = this.selectedRowType;
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], { queryParams: { drType, clone: this.selection.selected[0].id } });
  }

  routeToCreatePage() {
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }
}
