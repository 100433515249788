import { Component, OnInit, Input } from '@angular/core';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { OperatorsService } from '../../../shared/services/operators.service';
import { TimezonesService } from '../../../shared/services/timezones.service';
import { LocalesService } from '../../../shared/services/locales.service';
import {Locale, LocaleBaseConfig} from '../../../shared/model/locale.model';
import { Timezone } from '../../../shared/model/timezone.model';
import { ControlContainer, NgForm } from '@angular/forms';
import { Status } from '../../../shared/model/status.model';
import { RefService } from '../../../shared/services/ref.service';
import {TranslateService} from "@ngx-translate/core";
import {Program} from "../../../shared/model/program.model";
import {FormValidatorService} from "../../../shared/services/form-validator.service";

@Component({
  selector: 'app-program-blue-button-fields',
  templateUrl: './program-blue-button-fields.component.html',
  styleUrls: ['./program-blue-button-fields.component.css', '../../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ProgramBlueButtonFieldsComponent implements OnInit {
  org = '';
  operators = [];
  locales: Locale[];
  statuses: Status[];
  timezones: Timezone[];
  @Input() mode;
  @Input() program: Program;
  @Input() frm;
  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };
  programLabel: '';
  programPlaceholder: '';
  shortDisplayLabel: '';
  shortDisplayLabelPlaceholder: '';
  classicProgramNameLabel: '';
  classicProgramNamePlaceholder: '';
  descriptionLabel: '';
  descriptionPlaceholder: '';
  userLocale = 'en_US';

  get isEditMode() {
    return this.mode === 'edit';
  }

  get isCreateMode() {
    return this.mode === 'create';
  }

  get isViewMode() {
    return this.mode === 'view';
  }

  constructor(
      private ngxCookieService: NgxCookieService,
      private operatorsService: OperatorsService,
      private timezonesService: TimezonesService,
      private localesService: LocalesService,
      private refService: RefService,
      private translateService: TranslateService,
      private formValidatorService: FormValidatorService
  ) {
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    this.timezones = timezonesService.timezones;

    this.localesService.locales$.subscribe((locales) => {
      if (locales?.length) {
        this.locales = locales;
        const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
        this.multiLocaleConfig.defaultLocale = locale;

        if (this.isCreateMode) {
          this.multiLocaleConfig.supportedLocales = [locale];
          this.program.locale = this.userLocale;
        } else if (this.isEditMode) {
          this.buildSupportedLocales();
        }
      }
    });
  }

  ngOnInit(): void {
    this.operatorsService.operators$.subscribe((operators) => {
      if (operators.length > 0 && this.operators.length !== operators.length) {
        this.operators = operators;
      }
    });

    this.refService.statuses$.subscribe((statuses) => {
      if (statuses && statuses.length > 0) {
        this.statuses = statuses;
      }
    });
    this.refService.getStatuses();

    this.programLabel = this.translateService.instant('program.create.program_display_label');
    this.programPlaceholder = this.translateService.instant('program.create.placeholder.program_name');

    this.shortDisplayLabel = this.translateService.instant('program.create.short_display_label');
    this.shortDisplayLabelPlaceholder = this.translateService.instant('program.create.placeholder.short_display_label');

    this.classicProgramNameLabel = this.translateService.instant('program.create.classic_program_name');
    this.classicProgramNamePlaceholder = this.translateService.instant('program.create.placeholder.classic_program_name');

    this.descriptionLabel = this.translateService.instant('common.description');
    this.descriptionPlaceholder = this.translateService.instant('program.create.placeholder.program_description');
  }

  get operatorDisplayLabel() {
    if (this.program.operatorId && this.operators) {
      const foundOperator = this.operators.find((operator) => operator.id === this.program.operatorId);
      if (foundOperator) {
        return foundOperator.displayLabel;
      }
    }
  }

  getTimezoneForProgram() {
    if (this.timezones.length > 0 && this.program.timezone) {
      return (
          this.timezones.find((timezone) => this.program.timezone.indexOf(timezone.timezoneName) !== -1) || new Timezone()
      );
    }

    return new Timezone();
  }

  getLocaleForProgram() {
    if (this.locales?.length > 0) {
      return this.locales.find((locale) => this.program.locale.indexOf(locale.localeName) !== -1) || new Locale();
    }

    return new Locale();
  }

  getStatusForProgram() {
    if (this.statuses && this.statuses.length > 0) {
      return (
          this.statuses.find((status) => status.code === this.program.status)?.displayLabel || new Status().displayLabel
      );
    }

    return '-';
  }

  async handleSelectionChange() {
    if (this.multiLocaleConfig.supportedLocales.length === 0) {
      this.multiLocaleConfig.supportedLocales = [
        ...this.multiLocaleConfig.supportedLocales,
        this.multiLocaleConfig.defaultLocale,
      ];
    } else if (this.multiLocaleConfig.supportedLocales.length === 1) {
      this.formValidatorService.resetFieldDefaultLocaleField(
          this.frm.form['controls']['blueButtonFields']['controls'].locale,
      );
    }
    this.frm.form.controls['localeInputsForm_display_labels']?.markAsDirty();
    this.frm.form.controls['localeInputsForm_program_descriptions']?.markAsDirty();
    this.frm.form.controls['localeInputsForm_short_display_labels']?.markAsDirty();
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return (
        this.multiLocaleConfig.supportedLocales.length > num - 1 &&
        !this.multiLocaleConfig.supportedLocales.includes(locale)
    );
  }

  buildSupportedLocales() {
    if (this.multiLocaleConfig.supportedLocales && this.program && this.locales) {
      this.multiLocaleConfig.supportedLocales = [];
      const keys = Object.keys(this.program.displayLabels);
      for (const key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.multiLocaleConfig.supportedLocales.push(localeFound);
        }
      }
    }
  }

  getProgramDisplayLabel(): string {
    return this.localesService.getValueFromMultilocaleObject(this.program.displayLabels, this.userLocale, this.program.locale);
  }

  getShortDisplayLabel(): string {
    return this.localesService.getValueFromMultilocaleObject(this.program.shortDisplayLabels, this.userLocale, this.program.locale);
  }

  getDescriptions(): string {
    return this.localesService.getValueFromMultilocaleObject(this.program.displayLabels, this.userLocale, this.program.locale);
  }
}
